import { AppEEDAppViewQuery } from '@/features/app-eed/views/app/__generated__/AppEEDAppViewQuery';
import { SpotRole } from '@/types/iot-portal';
import { AppEEDConsumptionMixinQuery } from './mixins/__generated__/AppEEDConsumptionMixinQuery';

export type ConsumptionMetric =
  | AppEEDConsumptionMixinQuery['spots']['items'][number]['metrics'][number]
  | AppEEDConsumptionMixinQuery['spots']['items'][number]['beforeMetrics'][number];

export type ContinuousConsumptionMetric = ConsumptionMetric & {
  __typename: 'ContinuousMetric';
};

export type ContinuousConsumptionMetricWithPoints =
  AppEEDConsumptionMixinQuery['spots']['items'][number]['metrics'][number] & {
    __typename: 'ContinuousMetric';
  };

export type ConsumptionAttribute = AppEEDConsumptionMixinQuery['spots']['items'][number]['attributes'][number];

export type ConsumptionSpots = AppEEDConsumptionMixinQuery['spots'];

export type ConsumptionSpotsItems = AppEEDConsumptionMixinQuery['spots']['items'];

export type ConsumptionSpotItem = AppEEDConsumptionMixinQuery['spots']['items'][number];

export type PropertyTreeNodeAttribute = AppEEDAppViewQuery['treeNode']['first']['attributes'][number];

export enum Unit {
  KWH = 'kWh',
}

export enum HeatConsumptionAttributesType {
  CONVERSION_FACTOR = 'Umrechnungsfaktor',
  BASE_SENSITIVITY = 'Basisempfindlichkeit',
}

export enum WaterConsumptionAttributesType {
  CALORIC_VALUE = 'Heiz-/Brennwert',
  OPTIONAL_ATTRIBUTE = 'Zustandszahl',
}

export type ConsumptionRole =
  | typeof SpotRole.HEAT_COST_ALLOCATOR
  | typeof SpotRole.HEAT_METER_COUNTER
  | typeof SpotRole.WATER_METER_HOT;

export type HeatConsumptionRole = typeof SpotRole.HEAT_COST_ALLOCATOR | typeof SpotRole.HEAT_METER_COUNTER;

export type ConsumptionAttributeType = HeatConsumptionAttributesType | WaterConsumptionAttributesType;

export enum DateOperation {
  START = 'start',
  END = 'end',
  PREVIOUS_END = 'previousEnd',
  PREVIOUS_START = 'previousStart',
}

export enum DateUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export enum PeriodType {
  CURRENT_MONTH = 'currentMonth',
  PREVIOUS_MONTH = 'previousMonth',
  PREVIOUS_YEAR = 'previousYear',
  CURRENT_YEAR = 'currentYear',
  CURRENT_AND_PREVIOUS_YEAR = 'currentAndPreviousYear',
  CURRENT_MONTH_PREVIOUS_YEAR = 'currentMonthPreviousYear',
  CURRENT_YEAR_UNTIL_PREVIOUS_MONTH = 'currentYearUntilPreviousMonth',
  CURRENT_AND_PREVIOUS_YEAR_PREVIOUS_MONTH = 'currentAndPreviousYearPreviousMonth',
}

export enum DigitPlaces {
  DIGITS_NUMBER = 3,
}
