import { AttributeMissingError } from '@/features/domain-ui/eed-consumption/error/AttributeMissingError';
import {
  ConsumptionAttribute,
  PropertyTreeNodeAttribute,
  WaterConsumptionAttributesType,
} from '@/features/domain-ui/eed-consumption/model';
import { roundDecimalPlaces } from '@/features/domain-ui/eed-consumption/util';

type Attribute = ConsumptionAttribute & PropertyTreeNodeAttribute;

export function getFilteredAttributesList(
  attributes: Attribute[],
  attributeType: WaterConsumptionAttributesType,
): Attribute[] | undefined {
  return attributes.filter((e) => e.configuration.name === attributeType);
}

export function getAttributeValue(attributesList: Attribute[]): number | null {
  return attributesList.length == 1 && attributesList[0].value ? parseFloat(attributesList[0].value) : null;
}

export function getAttributeValueFromList(
  attributes: Attribute[],
  attributeType: WaterConsumptionAttributesType,
  isRequired = false,
): number | null {
  const attributesList = getFilteredAttributesList(attributes, attributeType);
  if (isRequired && (!attributesList || attributesList.length === 0)) {
    throw new AttributeMissingError(attributeType);
  }
  if (!attributesList) {
    return null;
  }
  if (attributesList.length > 1) {
    throw new Error(`Multiple ${attributeType} attributes values`);
  }
  return getAttributeValue(attributesList);
}

export function calculateWaterConsumption(
  lastValue: number,
  previousValue: number,
  propertyAttributes: PropertyTreeNodeAttribute[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _spotAttributes: ConsumptionAttribute[],
): number {
  const attributeHeatValue = getAttributeValueFromList(
    propertyAttributes,
    WaterConsumptionAttributesType.CALORIC_VALUE,
    true,
  );

  const optionalAttributeValue = getAttributeValueFromList(
    propertyAttributes,
    WaterConsumptionAttributesType.OPTIONAL_ATTRIBUTE,
  );

  return roundDecimalPlaces(
    (lastValue - previousValue) * (attributeHeatValue || 1.0) * (optionalAttributeValue || 1.0),
  );
}
