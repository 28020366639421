import numeral from '@/util/numeral-locale';
import { ConsumptionSpotsItems, DigitPlaces } from '../model';

export function spotContainsMetrics(spots: ConsumptionSpotsItems): boolean {
  return spots.some((spot) => spot.metrics.length > 0);
}

export function calculatePercentageDiff(previousConsumption: number, currentConsumption: number): number {
  if (previousConsumption === 0 && currentConsumption === 0) {
    return 0;
  }
  if (previousConsumption === 0) {
    return 100;
  }

  if (previousConsumption === 0 && currentConsumption === 0) {
    return 0;
  }
  if (previousConsumption === 0) {
    return 100;
  }

  return ((currentConsumption - previousConsumption) / previousConsumption) * 100.0;
}

export function roundDecimalPlaces(value: number, digits?: number): number {
  return parseFloat(value.toFixed(digits ?? DigitPlaces.DIGITS_NUMBER));
}

export function formatConsumption(value: number): string {
  return numeral(value).format('0.0,0[00]');
}
